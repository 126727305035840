import clsx from "clsx";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import {
  buyerProfileState,
  intakeFormEnabledState,
  requireIntakeFormEnabledState,
} from "../../../jotai/user";
import { buyerWelcomePageState } from "../../../jotai/welcome";
import {
  CardContainer,
  CardContainerVariant,
  PageSection,
} from "../../../library";
import { useHeaderTopClass } from "../../../shared/Header/constants";
import SearchBarCard from "../../../shared/SearchBarCard";
import { useShowSidebar } from "../../../shared/Sidebar/useShowSidebar";
import { recommendedSearchesByAgencyType } from "../../../shared/SuggestedSearches";
import { elevationClass } from "../../../utils/designTokens";
import { SearchSource } from "../../../utils/enums";
import { isFeatureEnabled } from "../../../utils/features";
import { QuoteRequestBanner } from "../../QuoteRequest/QuoteRequestBanner";
import BuyerFAQ from "./BuyerFAQ";
import BuyerSummary from "./BuyerSummary";
import PartnershipUpsellCard from "./PartnershipUpsellCard";
import ProjectsCard from "./ProjectsCard";
import PurchaseIntentCard from "./PurchaseIntentCard";
import QuickLinks from "./QuickLinks";
import StartPurchaseCard from "./StartPurchaseCard";

export default function BuyerProfilePage() {
  const showSidebar = useShowSidebar();
  const headerTopClass = useHeaderTopClass();
  const { governmentAgency } = useAtomValue(buyerProfileState);
  const { recentSearches, cpoAnalytics, cpoUpsell } = useAtomValue(
    buyerWelcomePageState
  );
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const requireIntakeForm = useAtomValue(requireIntakeFormEnabledState);
  const suggestedSearches = useMemo(() => {
    if (recentSearches.length) return recentSearches;

    const agencyType = governmentAgency?.agencyType || "";
    const recommendedSearchKey =
      agencyType && agencyType in recommendedSearchesByAgencyType
        ? agencyType
        : "DEFAULT";
    return recommendedSearchesByAgencyType[recommendedSearchKey];
  }, [recentSearches, governmentAgency]);
  const showAnalytics = cpoAnalytics || cpoUpsell;

  const showPrimaryIntake =
    isFeatureEnabled("projectBrowsing") && requireIntakeForm;
  const showIntake =
    !showPrimaryIntake &&
    isFeatureEnabled("projectBrowsing") &&
    intakeFormEnabled;
  const showProjects =
    !showIntake && !showPrimaryIntake && !showAnalytics && !showSidebar;

  return (
    <div>
      <PageSection>
        <div
          className={clsx(
            "absolute left-0 right-0 -z-1 h-[14rem] bg-gradient-to-b from-cp-persimmon-50 to-cp-white-100",
            headerTopClass
          )}
        />
        <div className="py-10 grid gap-6 grid-cols-12">
          <div
            className={clsx("grid gap-3 col-span-full h-fit", {
              "lg:col-span-4": !showSidebar,
            })}
          >
            <BuyerSummary />
            {!showSidebar && <QuickLinks />}
          </div>
          <div
            className={clsx("grid gap-6 col-span-full", {
              "lg:col-span-8": !showSidebar,
            })}
          >
            {showPrimaryIntake && <StartPurchaseCard />}
            {showPrimaryIntake && !showSidebar && <ProjectsCard />}
            {!showPrimaryIntake && showSidebar && <PurchaseIntentCard />}
            {!showPrimaryIntake && !showSidebar && (
              <SearchBarCard
                searchSource={SearchSource.BUYER_WELCOME_PAGE}
                suggestedSearchTerms={suggestedSearches}
                suggestedSearchTitle={
                  recentSearches.length > 1
                    ? "Recent searches"
                    : "Example searches"
                }
              />
            )}
            {showIntake && <StartPurchaseCard />}
            {showAnalytics && <PartnershipUpsellCard />}
            <QuoteRequestBanner />
            {showProjects && <ProjectsCard />}
            <CardContainer
              variant={CardContainerVariant.SECONDARY}
              className={clsx(
                "p-6 w-full rounded-6",
                elevationClass["elevation-2"]
              )}
            >
              <BuyerFAQ />
            </CardContainer>
          </div>
        </div>
      </PageSection>
    </div>
  );
}
