import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useMemo } from "react";

import { ApiService, ItemTypeEnum } from "../../generated";
import useRefreshSavedSuppliers from "../../hooks/useRefreshSavedSuppliers";
import useRequestID from "../../hooks/useRequestID";
import useSaveProjectItem from "../../hooks/useSaveProjectItem";
import { pageTypeState } from "../../jotai/page";
import {
  deleteProjectPurchaseOptionSupplierAtom,
  projectContextState,
  projectPurchaseOptionSuppliersLoadable,
  savedSuppliersAtom,
} from "../../jotai/projects";
import { Button, ButtonSizes, ButtonThemes } from "../../library";
import { PageType } from "../../utils/enums";
import { isFeatureEnabled } from "../../utils/features";
import { trackSaveSupplierToRequest } from "../../utils/tracking";
import { ProjectCreationSource } from "../Project/types";

function useProjectSavedSuppliers() {
  const projectContext = useAtomValue(projectContextState);
  const savedSuppliers = useAtomValue(savedSuppliersAtom);
  const purchaseOptionSuppliers = useAtomValue(
    projectPurchaseOptionSuppliersLoadable
  );

  return useMemo(() => {
    if (projectContext?.purchaseRequest?.selectedOption) {
      return purchaseOptionSuppliers.state === "hasData"
        ? purchaseOptionSuppliers.data
        : [];
    }
    return savedSuppliers;
  }, [projectContext, purchaseOptionSuppliers, savedSuppliers]);
}

function useDeleteProjectSupplier() {
  const deleteProjectSupplier = useSetAtom(
    deleteProjectPurchaseOptionSupplierAtom
  );
  const projectContext = useAtomValue(projectContextState);
  const refreshSavedSuppliers = useRefreshSavedSuppliers();

  return useCallback(
    async (supplierId: number) => {
      if (!projectContext) return;

      if (projectContext.purchaseRequest?.selectedOption) {
        deleteProjectSupplier({ id: supplierId });
      } else {
        await ApiService.apiV1ProjectsSuppliersDestroy(
          projectContext.id,
          supplierId.toString()
        );
        refreshSavedSuppliers();
      }
    },
    [deleteProjectSupplier, projectContext, refreshSavedSuppliers]
  );
}

export default function SaveSupplierToCurrentProjectButton({
  supplierId,
}: { supplierId: number }) {
  const requestID = useRequestID();
  const projectContext = useAtomValue(projectContextState);
  const projectSuppliers = useProjectSavedSuppliers();
  const deleteProjectSupplier = useDeleteProjectSupplier();
  const pageType = useAtomValue(pageTypeState);

  const saveProjectItem = useSaveProjectItem(
    ProjectCreationSource.SUPPLIER_SERP
  );

  const isSaved = useMemo(
    () => projectSuppliers.find((s) => s.supplier.id === supplierId),
    [supplierId, projectSuppliers]
  );

  if (
    !isFeatureEnabled("projectBrowsing") ||
    !(
      projectContext?.purchaseRequest?.selectedOption ||
      projectContext?.purchaseIntent
    )
  )
    return;

  let label = pageType === PageType.INTAKE ? "Save for review" : "Save";
  if (isSaved) label = "Saved";

  return (
    <Button
      size={ButtonSizes.SMALL}
      theme={ButtonThemes.TERTIARY_DARK}
      badgeProps={{
        Icon: isSaved ? BookmarkIcon : BookmarkBorderOutlinedIcon,
        reverse: true,
      }}
      dataTestId={`save-supplier-${supplierId}`}
      onClick={(e) => {
        e.stopPropagation();
        if (isSaved) {
          deleteProjectSupplier(supplierId);
          return;
        }
        trackSaveSupplierToRequest({
          projectId: projectContext.id,
          purchaseRequestId: projectContext.purchaseRequest?.id,
          purchaseOptionId: projectContext.purchaseRequest?.selectedOption?.id,
          requestID,
          supplierId,
          pageType,
        });
        saveProjectItem({
          projectId: projectContext.id,
          itemId: supplierId.toString(),
          itemType: ItemTypeEnum.SUPPLIER,
          purchaseOptionId: projectContext.purchaseRequest?.selectedOption?.id,
        });
      }}
    >
      {label}
    </Button>
  );
}
