import { captureMessage } from "@sentry/browser";
import { useAtom, useAtomValue } from "jotai";
import { useMemo } from "react";
import { getProjectDetailURL } from "../../components/Project/utils";
import {
  PURCHASE_INTENT_DEFAULT_VALUES,
  PurchaseIntentForm,
} from "../../components/PurchaseIntentForm";
import {
  ApiService,
  type CreatePurchaseIntentRequest,
  type ProjectPurchaseIntent,
} from "../../generated";
import useShowModal from "../../hooks/useShowModal";
import {
  preferredSuppliersLoadable,
  projectContextState,
} from "../../jotai/projects";
import { goToURL } from "../../utils";
import { modals } from "../../utils/enums";
import { getSearchPath } from "../../utils/format";
import { handleError } from "../../utils/generatedApi";
import Modal, { modalSizes } from "../Modal/Modal";

interface PurchaseIntentModalProps {
  hideModal: () => void;
  onSave?: (purchaseIntent?: ProjectPurchaseIntent) => void;
  purchaseIntent?: ProjectPurchaseIntent;
}

export function usePurchaseIntentModal() {
  const show = useShowModal(modals.PURCHASE_INTENT);
  return (props: Omit<PurchaseIntentModalProps, "hideModal">) => show(props);
}

export default function PurchaseIntentModal({
  hideModal,
  onSave,
  purchaseIntent,
}: PurchaseIntentModalProps) {
  const preferredSuppliers = useAtomValue(preferredSuppliersLoadable);
  const [projectContext, setProjectContext] = useAtom(projectContextState);

  const initialValues = useMemo(() => {
    if (!purchaseIntent) return PURCHASE_INTENT_DEFAULT_VALUES;
    return {
      ...purchaseIntent,
      suppliers:
        preferredSuppliers.state === "hasData" ? preferredSuppliers.data : [],
    };
  }, [purchaseIntent, preferredSuppliers]);

  async function onCreate(values: CreatePurchaseIntentRequest) {
    try {
      const response = await ApiService.apiV1PurchaseIntentsCreate(values);
      if (response.projectId) {
        goToURL(getProjectDetailURL(response.projectId), {}, false);
        return;
      }
      captureMessage("No project ID returned from purchase intent creation");
      goToURL(
        getSearchPath({
          query: values.query || "",
          queryZip: "",
          analyticsSearchSource: "purchase-intent-modal",
        }),
        {}
      );
    } catch (err) {
      handleError(err);
    }
  }

  async function onUpdate(id: number, values: CreatePurchaseIntentRequest) {
    try {
      await ApiService.apiV1PurchaseIntentsUpdate(id, values);

      if (projectContext) {
        const project = await ApiService.apiV1ProjectsRetrieve(
          projectContext.id
        );
        setProjectContext(project);
        if (!project?.purchaseIntent) return;
        onSave?.(project.purchaseIntent);
      }
    } catch (err) {
      handleError(err);
    }
  }

  async function onSubmit(values: CreatePurchaseIntentRequest) {
    if (purchaseIntent) {
      await onUpdate(purchaseIntent.id, values);
    } else {
      await onCreate(values);
    }
    hideModal();
  }

  return (
    <Modal
      hideModal={hideModal}
      className="analytics-intake-edit-modal"
      title={purchaseIntent ? "Edit request info" : "New purchase request"}
      modalSize={modalSizes.DEFAULT}
    >
      <PurchaseIntentForm onSubmit={onSubmit} initialValues={initialValues} />
    </Modal>
  );
}
