import CheckRounded from "@mui/icons-material/CheckRounded";
import type { Getter, Setter } from "jotai";
import { useAtomCallback } from "jotai/utils";
import { useCallback } from "react";
import type { ProjectCreationSource } from "../components/Project/types";
import { ApiService, type ItemTypeEnum } from "../generated";
import { popupState } from "../jotai/page";
import {
  projectContextState,
  projectPurchaseOptionSuppliersAtom,
  savedProjectState,
} from "../jotai/projects";
import { Badge, Link, Typography } from "../library";
import { useSaveToProjectButtonProps } from "../shared/saveToProjectUtils";
import { PopupType } from "../utils/enums";
import { handleError } from "../utils/generatedApi";
import { trackSaveToProject } from "../utils/tracking";
import useRefreshSavedSuppliers from "./useRefreshSavedSuppliers";

export default function useSaveProjectItem(source?: ProjectCreationSource) {
  const saveButtonProps = useSaveToProjectButtonProps();
  const refreshSavedSuppliers = useRefreshSavedSuppliers();

  const saveProjectItem = useCallback(
    async (
      get: Getter,
      set: Setter,
      {
        projectId,
        itemId,
        itemType,
        purchaseOptionId,
      }: {
        projectId: string;
        itemId: string;
        itemType: ItemTypeEnum;
        purchaseOptionId?: number;
      }
    ) => {
      const projectContext = get(projectContextState);
      try {
        await ApiService.apiV1ProjectsItemsCreate(projectId, {
          itemId,
          itemType,
          purchaseOption: purchaseOptionId,
        });
        set(savedProjectState, { id: projectId, type: itemType });
        set(popupState, {
          analyticsClassName: source
            ? `analytics-new-project-success-${source}`
            : "analytics-create-projects-success",
          name: PopupType.SUCCESS,
          durationSeconds: 5,
          children: (
            <Badge Icon={CheckRounded} iconClass="!mr-4">
              <Typography color="inverse">
                {saveButtonProps[itemType].viewSavedCtaCopy}
                {projectContext?.id ? (
                  ""
                ) : (
                  <>
                    {" "}
                    <Link
                      color="inverse"
                      emphasis={false}
                      href={`/projects/${projectId}`}
                    >
                      here
                    </Link>
                    .
                  </>
                )}
              </Typography>
            </Badge>
          ),
          show: true,
        });
        trackSaveToProject({ itemId, itemType });
        if (projectContext?.purchaseRequest?.selectedOption) {
          set(projectPurchaseOptionSuppliersAtom);
        } else {
          refreshSavedSuppliers();
        }
      } catch (e) {
        handleError(e);
      }
    },
    [saveButtonProps, source, refreshSavedSuppliers]
  );

  return useAtomCallback(saveProjectItem);
}
