import AddRoundedIcon from "@mui/icons-material/AddRounded";
import { useAtomValue } from "jotai";
import { useMemo } from "react";

import { intakeFormEnabledState } from "../../jotai/user";
import { Button, Link } from "../../library";
import { usePurchaseIntentModal } from "../SearchModal/PurchaseIntentModal";

export default function NewRequestButton({
  className,
  dataTestId,
  onClick,
}: { className?: string; dataTestId?: string; onClick?: () => void }) {
  const intakeFormEnabled = useAtomValue(intakeFormEnabledState);
  const showPurchaseIntent = usePurchaseIntentModal();

  const props = useMemo(() => {
    if (intakeFormEnabled) {
      return {
        linkProps: {
          href: "/intake",
          underline: false,
          newWindow: false,
          onClick,
        },
        as: Link,
      };
    }

    return {
      onClick: () => {
        showPurchaseIntent({});
        onClick?.();
      },
    };
  }, [intakeFormEnabled, onClick, showPurchaseIntent]);

  return (
    <Button
      theme={Button.themes.PRIMARY_DARK}
      size={Button.sizes.SMALL}
      className={className}
      dataTestId={dataTestId}
      badgeProps={{ Icon: AddRoundedIcon }}
      {...props}
    >
      New request
    </Button>
  );
}
